.indiesoft-video-parent {
  position: relative; }

.indiesoft-video {
  @extend %overlay;
  overflow: hidden;
  video {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    max-width: initial;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); }
  // Modifiers
  &.is-transparent {
    opacity: 0.3; } }
