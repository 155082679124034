$family-sans-serif: "Cera Round Pro", "Arial", sans-serif;
$family-primary: $family-sans-serif;
$block-spacing: 3rem;
$footer-padding: 3rem 1.5rem 3rem;
// $section-padding: 6rem 1.5rem;
// $section-padding-medium: 9rem 1.5rem;
// $section-padding-large: 18rem 1.5rem;
$primary: #5ccbe6;
$dark: #001a90;

@font-face {
  font-family: "Cera Round Pro";
  src: url("../../fonts/CeraRoundPro-Medium.woff2") format("woff2"),
    url("../../fonts/CeraRoundPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cera Round Pro";
  src: url("../../fonts/CeraRoundPro-Regular.woff2") format("woff2"),
    url("../../fonts/CeraRoundPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
